@use "./variables" as *;
@use "./typography" as *;
@use "./mixins" as *;

*,
*::before,
*::after{
    box-sizing: border-box;
    margin: 0;
}
body{
    font-family: GeneralSans, sans-serif;
}
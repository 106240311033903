@use "../../styles/partials/typography" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/_global" as *;
@use "../../styles/partials/_variables" as *;

.home{
    &--container{
    display: block;
        @include tablet-and-desktop{
            display: flex;
            flex-direction: row;
        }
    }
    p::selection,
    h1::selection,
    h2::selection,
    h3::selection,
    li::selection,
    img::selection
     {
        background-color: $third-website-color;
        color: white;
    }
    &--leftside{
        @include tablet-and-desktop{
            height: 100vh;
            position: sticky;
            top: 0;
            padding-top: 3rem;
            width: 35vw;
        }
    }
    &--rightside{
        @include tablet-and-desktop{
            padding-top: 0;
            box-sizing: border-box;
        }
    }
}
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/_global" as *;
@use "../../styles/partials/_variables" as *;

.projects{
    padding: 0 2rem 1rem 2rem;
    background-color: $main-bg-color;
    color: white;
    &--title{
        font-size: 1.5rem;
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: inherit;
        padding: 1rem 0 1rem 0;
        @include tablet-and-desktop{
            padding: 0 0 2rem 0;
            height: 8rem;
            display: flex;
            align-items: end;
        }
    }
    &--titlecontainer{
        display: flex;
        align-items: center;
        &:hover {
            .projects--arrowicon {
                transform: rotate(0deg);
                filter: $hover-filter;
            }
            .projects--subtitle {
                color: $secondary-main-color;
            }
        }
    }
    &--arrowicon{
        height: 0.5rem;
        transform: rotate(-45deg);
        transition: transform 0.3s ease, filter 0.3s ease;
    }
    &--link{
        text-decoration: none;
        color: inherit;
    }
    &--subtitle{
        font-size: 1rem;
        padding: 1rem 1rem 1rem 0;
        cursor: pointer;
        transition: color 0.3s ease;
    }
    &--subtitle:hover{
        color: $secondary-main-color;
    }
    &--body{
        color: $medium-color;
        padding-bottom: 1.5rem;
    }
    &--projectimage{
        border-radius: 6px;
        width: 13rem;
        padding: 1rem 0 0 0;
        cursor: pointer;
        transition: filter 1s ease;
        @include tablet-and-desktop{
            width: 16rem;
        }
    }
    &--projectimage:hover{
        filter: hue-rotate(180deg);
    }
    &--techstack{
        padding: 1.5rem 0 0 0;
        display: flex;
        flex-wrap: wrap;
        font-size: 0.8rem;
        &__item{
            color: $secondary-main-color;
            margin: 0.25rem;
            list-style-type: none;
            background-color: $light-background-color;
            padding: 0.1rem 0.5rem 0.1rem 0.5rem;
            border-radius: 15px;
        }
    }
}
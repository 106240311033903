$tablet-breakpoint: 768px;
$desktop-breakpoint: 1280px;
$main-bg-color: #1b231d;
$secondary-main-color: #b0ffd9;
$third-website-color: #91a7f2;
$light-background-color: #3b423e;
$medium-color: #ababab;
$main-title-fontsize: 2rem;
$main-title-fontweight: 700;
$sub-title-fontsize: 1.5rem;
$sub-title-fontweight: 600;
$hover-filter: brightness(0) saturate(100%) invert(81%) sepia(41%) saturate(222%) hue-rotate(75deg) brightness(98%) contrast(95%);
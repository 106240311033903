@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/_global" as *;

.intro{
    padding: 2rem 2rem 1rem 2rem;
    background-color: $main-bg-color;
    color: white;
    @include tablet-and-desktop{
        padding-top: 1rem;
        width: 36vw;
    }
    &--title{
        font-size: $main-title-fontsize;
        font-weight: $main-title-fontweight;
    }
    &--subtitle{
        margin-top: 1rem;
        font-size: 1rem;
        font-weight: $sub-title-fontweight;
    }
    &--body{
        margin: auto;
        color: $medium-color;
        padding-top: 2rem;
    }
}
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/_global" as *;
@use "../../styles/partials/_variables" as *;

.connect{
        background-color: $main-bg-color;
        list-style-type: none;
        color: white;
        display: flex;
        flex-direction: row;
        justify-content:left;
        gap: 4vw;
        padding: 0 0 1rem 3rem;
    &--link{
        target: blank;
    }
    &--img{
        width: 20px;
        cursor: pointer;
        transition: filter 0.3s ease;
    }
    &--img:hover{
        filter: $hover-filter;
    }
}
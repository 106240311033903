@use "../../styles/partials/variables" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/_global" as *;

.header{
    padding: 1rem 0 0 0;
    background-color: $main-bg-color;
    &--container{
        display: flex;
        flex-direction: row;
        gap: 3vw;
    }
    &--item{
        color: $secondary-main-color;
        font-size: 0.7rem;
        list-style-type: none;
    }
}